import { defineStore } from 'pinia'

export const usePropertyStore = defineStore('property', {
  persist: {
    storage: persistedState.localStorage,
  },

  state: () => ({
    recentlyViewed: <Partial<Sanity.Default.Schema.Properties[]>>[],
    favourites: <Partial<Sanity.Default.Schema.Properties[]>>[],
  }),

  // add an isFavourite getter to check if a property is in the favourites list
  getters: {
    isFavourite: state => (propertySlug: string | undefined) => {
      if (!propertySlug)
        return false

      return state.favourites.some(p => p?.slug.current === propertySlug)
    },
  },

  actions: {
    addFavourite(property: Sanity.Default.Schema.Properties) {
      console.log('addFavourite', property)
      if (!property)
        return

      if (!property.slug)
        return

      if (!property.files || property.files.length === 0)
        return

      // if the property is already in the list, return
      if (this.favourites.some(p => p?.slug.current === property.slug.current))
        return

      // copy the property to a new object that retains only the necessary fields
      const newProperty = {
        _type: property._type,
        _id: property._id,
        slug: {
          _type: property.slug._type,
          current: property.slug.current,
        },
        title: property.title,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        receptions: property.receptions,
        landarea: property.landarea,
        price: property.price,
        files: property.files,
        sale: property.sale,
        let: property.let,
        web_status: property.web_status,
      }

      // add the property to the start of the list
      this.favourites.unshift(newProperty)

      // keep the list to a maximum of 6 items
      if (this.favourites.length > 6) {
        this.favourites.pop()
      }
    },
    removeFavourite(slugCurrent: string | undefined) {
      if (!slugCurrent)
        return

      const index = this.favourites.findIndex(p => p?.slug.current === slugCurrent)
      if (index !== -1) {
        this.favourites.splice(index, 1)
      }
    },
    add(property: Sanity.Default.Schema.Properties) {
      if (!property)
        return

      if (!property.slug)
        return

      if (!property.files || property.files.length === 0)
        return

      // if the property is already in the list, return
      if (this.recentlyViewed.some(p => p?.slug.current === property.slug.current))
        return

      // copy the property to a new object that retains only the necessary fields
      const newProperty = {
        _type: property._type,
        _id: property._id,
        slug: {
          _type: property.slug._type,
          current: property.slug.current,
        },
        title: property.title,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        receptions: property.receptions,
        landarea: property.landarea,
        price: property.price,
        files: property.files,
        sale: property.sale,
        let: property.let,
        web_status: property.web_status,
      }

      // add the property to the start of the list
      this.recentlyViewed.unshift(newProperty)

      // keep the list to a maximum of 6 items
      if (this.recentlyViewed.length > 6) {
        this.recentlyViewed.pop()
      }
    },
    remove(slugCurrent: string) {
      const index = this.recentlyViewed.findIndex(p => p?.slug.current === slugCurrent)
      if (index !== -1) {
        this.recentlyViewed.splice(index, 1)
      }
    },
  },
})
